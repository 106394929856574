var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-sidebar", {
        attrs: {
          id: "sidebar-task-handler",
          "sidebar-class": "sidebar-lg",
          "bg-variant": "white",
          shadow: "",
          "no-header": "",
          right: "",
          visible: _vm.showHeaderEdit,
        },
        on: {
          change: function (val) {
            return _vm.$emit("update:show-header-edit-form", val)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var hide = ref.hide
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1",
                  },
                  [
                    _c("div", [_vm._v(" 헤더 편집 ")]),
                    _c(
                      "div",
                      [
                        _c("feather-icon", {
                          staticClass: "ml-1 cursor-pointer",
                          attrs: { icon: "XIcon", size: "16" },
                          on: { click: hide },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c("validation-observer", {
                  ref: "refFormObserver",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var handleSubmit = ref.handleSubmit
                          return [
                            _c(
                              "b-form",
                              {
                                staticClass: "p-2",
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return handleSubmit(_vm.onSubmit)
                                  },
                                  reset: function ($event) {
                                    $event.preventDefault()
                                    return _vm.resetForm($event)
                                  },
                                },
                              },
                              [
                                _c("validation-provider", {
                                  attrs: { name: "제목", rules: "required" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: "제목",
                                                  "label-for":
                                                    "survey-page-title",
                                                },
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "survey-page-title",
                                                    autofocus: "",
                                                    state:
                                                      errors.length > 0
                                                        ? false
                                                        : null,
                                                    placeholder: "제목",
                                                  },
                                                  model: {
                                                    value: _vm.header.title,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.header,
                                                        "title",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "header.title",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: "Intro",
                                      "label-for": "survey-page-description",
                                    },
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Intro",
                                        rules: "required",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("tinymce-editor", {
                                                  model: {
                                                    value:
                                                      _vm.header.description,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.header,
                                                        "description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "header.description",
                                                  },
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger",
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-2" },
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-start",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(186, 191, 199, 0.15)",
                                                expression:
                                                  "'rgba(186, 191, 199, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: {
                                              variant: "outline-secondary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return hide()
                                              },
                                            },
                                          },
                                          [_vm._v(" 닫기 ")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticClass:
                                          "d-flex align-items-center justify-content-end",
                                        attrs: { cols: "6" },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            staticClass: "mr-2",
                                            attrs: {
                                              variant: "primary",
                                              type: "submit",
                                            },
                                          },
                                          [_vm._v(" 적용(반영) ")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }