<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      no-header
      right
      :visible="showHeaderEdit"
      @change="(val) => $emit('update:show-header-edit-form', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <div>
            헤더 편집
          </div>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Title -->
            <validation-provider
              #default="{ errors }"
              name="제목"
              rules="required"
            >
              <b-form-group
                label="제목"
                label-for="survey-page-title"
              >
                <b-form-input
                  id="survey-page-title"
                  v-model="header.title"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  placeholder="제목"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Intro"
              label-for="survey-page-description"
            >
              <validation-provider
                #default="{ errors }"
                name="Intro"
                rules="required"
              >
                <tinymce-editor
                  v-model="header.description"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-col
                cols="6"
                class="d-flex align-items-center justify-content-start"
              >
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  초기화
                </b-button> -->
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="hide();"
                >
                  닫기
                </b-button>
              </b-col>
              <b-col
                cols="6"
                class="d-flex align-items-center justify-content-end"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-2"
                  type="submit"
                >
                  적용(반영)
                </b-button>
              </b-col>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
} from 'bootstrap-vue'
import { watch, getCurrentInstance } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import TinymceEditor from '@/views/TinyMCE.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BSidebar,
    TinymceEditor,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'showHeaderEdit',
    event: 'update:show-header-edit-form',
  },
  props: {
    showHeaderEdit: {
      type: Boolean,
      required: true,
    },
    header: {
      type: Object,
      required: false,
      default: () => ({ title: '', description: '' }),
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    watch(() => props.showHeaderEdit, newVal => {
      if (newVal) refs.refFormObserver.reset()
    })

    const onSubmit = () => {
      emit('update', props.header)
      emit('update:show-header-edit-form', false)
    }

    const resetForm = () => {
      const { header } = props

      header.title = ''
      header.description = ''
    }

    return {
      required,
      onSubmit,
      resetForm,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}
</style>
