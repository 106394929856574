var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "cancel-variant": "outline-secondary",
        centered: "",
        "ok-title": "적용",
        "cancel-title": "취소",
        size: "lg",
        title: "입력 항목 편집",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.submit()
        },
        close: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
        cancel: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
      },
      model: {
        value: _vm.showChoiceGridConfig,
        callback: function ($$v) {
          _vm.showChoiceGridConfig = $$v
        },
        expression: "showChoiceGridConfig",
      },
    },
    [
      _c(
        "validation-observer",
        { ref: "formRules" },
        [
          _c("span", [_vm._v("소질문(행)")]),
          _vm._l(_vm.filteredChoices(false), function (choice, idx) {
            return _c(
              "div",
              { key: "row-" + choice.seq, attrs: { id: "choice" + idx } },
              [
                _c(
                  "b-row",
                  { staticClass: "mt-50" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "10" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "소질문(행)", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "질문 내용 입력",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: choice.description,
                                        callback: function ($$v) {
                                          _vm.$set(choice, "description", $$v)
                                        },
                                        expression: "choice.description",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-start",
                        attrs: { cols: "2" },
                      },
                      [
                        idx === 0
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-primary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addChoice(false)
                                  },
                                },
                              },
                              [_vm._v(" 추가 입력 ")]
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-secondary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeChoice(choice)
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "Trash2Icon" },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c("div", { staticClass: "mt-1 mb-1" }),
          _c("span", [_vm._v("선택 항목(열)")]),
          _vm._l(_vm.filteredChoices(true), function (choice, idx) {
            return _c(
              "div",
              { key: "col-" + choice.seq, attrs: { id: "choice" + idx } },
              [
                _c(
                  "b-row",
                  { staticClass: "mt-50" },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "10" } },
                      [
                        _c("validation-provider", {
                          attrs: { name: "선택 항목(열)", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "선택 항목 입력",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: choice.description,
                                        callback: function ($$v) {
                                          _vm.$set(choice, "description", $$v)
                                        },
                                        expression: "choice.description",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-start",
                        attrs: { cols: "2" },
                      },
                      [
                        idx === 0
                          ? _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-primary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.addChoice(true)
                                  },
                                },
                              },
                              [_vm._v(" 추가 입력 ")]
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: { variant: "outline-secondary" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeChoice(choice)
                                  },
                                },
                              },
                              [
                                _c("feather-icon", {
                                  attrs: { icon: "Trash2Icon" },
                                }),
                              ],
                              1
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }