var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "cancel-variant": "outline-secondary",
        centered: "",
        "ok-title": "적용",
        "cancel-title": "취소",
        size: "lg",
        title: _vm.title,
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.submit()
        },
        close: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
        cancel: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
      },
      model: {
        value: _vm.showEditor,
        callback: function ($$v) {
          _vm.showEditor = $$v
        },
        expression: "showEditor",
      },
    },
    [
      _vm.description
        ? _c(
            "div",
            { staticClass: "p-1" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-center",
                      attrs: { cols: "12", md: "12" },
                    },
                    [_c("h5", [_vm._v(_vm._s(_vm.description))])]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("quill-editor", {
        attrs: { id: "survey-element-description", value: _vm.text },
        model: {
          value: _vm.bindText,
          callback: function ($$v) {
            _vm.bindText = $$v
          },
          expression: "bindText",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }