<!-- [팝업] 선택 항목 구성 -->
<template>
  <b-modal
    v-model="showElementGroupConfig"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="하위 문항 선택"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="m-1"
    >
      <b-col
        cols="12"
        class="d-flex justify-content-start"
      >
        <h5>하위 문항을 선택하세요</h5>
      </b-col>
    </b-row>
    <b-row
      class="m-1"
    >
      <b-col
        cols="12"
        class="d-flex justify-content-start"
      >
        <h5>※ 하위 문항(시작문항-종료문항) 지정 시 설문 페이지에서 그룹제목과 하위문항이 묶여진 것처럼 스타일이 적용됩니다. (*그룹제목과 하위문항 사이의 연결 관계는 없습니다.)</h5>
      </b-col>
    </b-row>
    <validation-observer ref="formRules">
      <b-row
        class="mt-1"
      >
        <b-col
          cols="2"
          class="d-flex align-items-center justify-content-center"
        >
          <b-badge variant="light-secondary">
            시작 문항
          </b-badge>
        </b-col>
        <b-col
          cols="10"
        >
          <validation-provider
            #default="{ errors }"
            name="시작 문항"
            rules="required"
          >
            <v-select
              v-model="groupStartElementSeq"
              :options="groupElements"
              :reduce="option => option.seq"
              placeholder="문항 선택"
              disabled
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        class="mt-1"
      >
        <b-col
          cols="2"
          class="d-flex align-items-center justify-content-center"
        >
          <b-badge variant="light-secondary">
            종료 문항
          </b-badge>
        </b-col>
        <b-col
          cols="10"
        >
          <validation-provider
            #default="{ failedRules }"
            name="종료 문항"
            :rules="{
              required: true,
              notEqual: groupStartElementSeq,
              after: groupStartElementSeq,
            }"
          >
            <v-select
              v-model="groupEndElementSeq"
              :options="groupEndElements()"
              :reduce="option => option.seq"
              placeholder="문항 선택"
            />
            <small class="text-danger">
              {{
                failedRules.hasOwnProperty('required')
                  ? '[종료 문항]는(은) 필수 입력입니다.'
                  : failedRules.hasOwnProperty('notEqual')
                    ? '[시작 문항]과(와) [종료 문항]는(은) 동일하게 선택할 수 없습니다.'
                    : failedRules.hasOwnProperty('after')
                      ? '[종료 문항]는(은) [시작 문항]보다 이전 문항을 선택할 수 없습니다.'
                      : ''
              }}
            </small>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { BBadge } from 'bootstrap-vue'
import {
  ref, onMounted, getCurrentInstance, watch,
} from '@vue/composition-api'
import { required, after, notEqual } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    vSelect,
    BBadge,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    showElementGroupConfig: {
      type: Boolean,
      required: true,
    },
    groupElements: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    subElementCount: {
      type: Number,
      required: false,
      default: 0,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
    })

    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const groupStartElementSeq = ref(null)
    const groupEndElementSeq = ref(null)

    watch(() => props.groupElements, () => {
      if (!props.groupElements || props.groupElements.length === 0) {
        groupStartElementSeq.value = null
      } else {
        groupStartElementSeq.value = props.groupElements[0].seq
      }

      if (props.subElementCount) {
        groupEndElementSeq.value = props.groupElements[(props.subElementCount - 1)].seq
      } else {
        groupEndElementSeq.value = null
      }
    })

    // watch(() => props.subElementCount, () => {
    //   if (!props.groupElements || props.groupElements.length === 0) return

    //   if (props.subElementCount) {
    //     groupEndElementSeq.value = props.groupElements[(props.subElementCount - 1)].seq
    //   } else {
    //     groupEndElementSeq.value = null
    //   }
    // })

    const groupEndElements = () => {
      const elements = props.groupElements

      return elements.filter((_, idx) => {
        if (idx === 0) return false // 시작 문항은 표시 제외

        return true
      })
    }

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        const result = {
          groupStartElementSeq: groupStartElementSeq.value,
          groupEndElementSeq: groupEndElementSeq.value,
        }

        emit('close', result)
      })
    }

    return {
      required,
      after,
      notEqual,
      close,
      submit,

      groupEndElements,
      groupStartElementSeq,
      groupEndElementSeq,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
