var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticStyle: { "min-width": "50%" } }, [
    _c(
      "colgroup",
      [
        _c("col", { attrs: { width: _vm.calcColWidth } }),
        _vm._l(_vm.getCols(), function (col) {
          return [
            _c("col", {
              key: "col-" + _vm.elSeq + "-" + col.seq,
              attrs: { width: _vm.calcColWidth },
            }),
          ]
        }),
      ],
      2
    ),
    _c("thead", [
      _c(
        "tr",
        { staticClass: "grid-line" },
        [
          _c("th"),
          _vm._l(_vm.getCols(), function (col) {
            return [
              _c(
                "th",
                {
                  key: "th-" + _vm.elSeq + "-" + col.seq,
                  staticStyle: { "text-align": "center" },
                },
                [_vm._v(" " + _vm._s(col.description) + " ")]
              ),
            ]
          }),
        ],
        2
      ),
    ]),
    _c(
      "tbody",
      [
        _vm._l(_vm.getRows(), function (row) {
          return [
            _c(
              "tr",
              {
                key: "row-" + _vm.elSeq + "-" + row.seq,
                staticClass: "grid-line",
              },
              [
                _c("td", [_vm._v(_vm._s(row.description))]),
                _vm._l(_vm.getCols(), function (col) {
                  return [
                    _c(
                      "td",
                      {
                        key: "answer-" + _vm.elSeq + "-" + col.seq,
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        _c("input", {
                          attrs: {
                            type: _vm.subType === 1 ? "radio" : "checkbox",
                            name: "group-" + _vm.elSeq + "-" + row.seq,
                          },
                        }),
                      ]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }