var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-50 content-area-wrapper" },
    [
      _c("div", { staticClass: "sidebar-left" }, [
        _c("div", { staticClass: "sidebar" }, [
          _c(
            "div",
            { staticClass: "side-container" },
            [
              _c(
                "b-list-group",
                { staticClass: "mt-1" },
                _vm._l(_vm.codes.elementType, function (elType) {
                  return _c(
                    "b-list-group-item",
                    {
                      key: elType.code,
                      staticStyle: { cursor: "pointer" },
                      attrs: { disabled: _vm.pageId === 0 || _vm.isSortMode },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addPageElement(elType)
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-75",
                        attrs: { icon: elType.icon, size: "18" },
                      }),
                      _c(
                        "span",
                        { staticClass: "align-text-bottom line-height-1" },
                        [_vm._v(_vm._s(elType.label))]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "content-right" },
        [
          _vm.pageId === 0
            ? [
                _c("header-view", {
                  attrs: { header: _vm.paperInfo, active: _vm.activeHeader },
                  on: {
                    "click-header": _vm.headerView.click,
                    "update-header": _vm.headerView.update,
                  },
                }),
              ]
            : [
                _c(
                  "b-row",
                  { staticClass: "mt-50 mb-2" },
                  [
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-end",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isSortMode,
                                expression: "isSortMode",
                              },
                            ],
                            staticClass: "ml-1",
                            attrs: { size: "sm", variant: "primary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.saveSorting()
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "SaveIcon" },
                            }),
                            _c("span", [_vm._v("순서 변경 저장")]),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isSortMode,
                                expression: "isSortMode",
                              },
                            ],
                            staticClass: "ml-1",
                            attrs: { size: "sm", variant: "outline-secondary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeSortMode(false)
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "XIcon" },
                            }),
                            _c("span", [_vm._v("취소")]),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.isSortMode && !_vm.readonly,
                                expression: "!isSortMode && !readonly",
                              },
                            ],
                            staticClass: "float-right",
                            attrs: { size: "sm", variant: "outline-secondary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.changeSortMode(true)
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "ListIcon" },
                            }),
                            _vm._v(" 정렬 순서 변경 "),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.readonly,
                                expression: "!readonly",
                              },
                            ],
                            staticClass: "float-right ml-1",
                            attrs: { size: "sm", variant: "outline-secondary" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removePage($event)
                              },
                            },
                          },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-50",
                              attrs: { icon: "Trash2Icon" },
                            }),
                            _vm._v(" 페이지 삭제 "),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("header-view", {
                  attrs: { header: _vm.pageInfo, active: _vm.activeHeader },
                  on: {
                    "click-header": _vm.headerView.click,
                    "update-header": _vm.headerView.update,
                  },
                }),
                _c(
                  "b-alert",
                  {
                    staticClass: "m-1",
                    attrs: { show: _vm.isSortMode, variant: "warning" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "alert-body",
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        _c("span", [
                          _vm._v("문항 정렬 순서 변경이 활성화 되었습니다."),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "draggable",
                  {
                    attrs: { options: { disabled: !_vm.isSortMode } },
                    model: {
                      value: _vm.pageElements,
                      callback: function ($$v) {
                        _vm.pageElements = $$v
                      },
                      expression: "pageElements",
                    },
                  },
                  [
                    _c(
                      "transition-group",
                      { staticClass: "grid-view wishlist-items" },
                      _vm._l(_vm.pageElements, function (el, elIdx) {
                        return _c(
                          "b-card",
                          {
                            key: "el-" + elIdx,
                            class: el.groupStyle
                              ? _vm.getElementAreaStyle(el)
                              : "element-area",
                            style: { opacity: el.isActive ? "1.0" : "0.3" },
                            attrs: {
                              "border-variant":
                                _vm.activeElement &&
                                _vm.currElement &&
                                _vm.currElement.seq === el.seq
                                  ? "primary"
                                  : "",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.clickElement(el)
                              },
                            },
                          },
                          [
                            !el.title
                              ? [
                                  _c("label", [
                                    _c("b", [_vm._v(_vm._s(el.typeName))]),
                                  ]),
                                  _c("h5", { staticClass: "mt-2" }, [
                                    _vm._v(
                                      " 문항 내용을 입력하려면 클릭하세요. "
                                    ),
                                  ]),
                                ]
                              : _vm._e(),
                            el.title
                              ? [
                                  _c(
                                    "h4",
                                    [
                                      el.type === 50
                                        ? [
                                            _c(
                                              "b-badge",
                                              {
                                                attrs: {
                                                  variant: "light-success",
                                                },
                                              },
                                              [_vm._v(" 그룹 ")]
                                            ),
                                          ]
                                        : [
                                            el.isRequired
                                              ? _c(
                                                  "b-badge",
                                                  {
                                                    attrs: {
                                                      variant: "light-primary",
                                                    },
                                                  },
                                                  [_vm._v(" 필수 ")]
                                                )
                                              : _c(
                                                  "b-badge",
                                                  {
                                                    attrs: {
                                                      variant:
                                                        "light-secondary",
                                                    },
                                                  },
                                                  [_vm._v(" 선택 ")]
                                                ),
                                          ],
                                      _vm._v(" " + _vm._s(el.title) + " "),
                                    ],
                                    2
                                  ),
                                  _c("div", {
                                    staticClass: "mt-1",
                                    domProps: {
                                      innerHTML: _vm._s(el.description),
                                    },
                                  }),
                                  el.type === 1
                                    ? [
                                        _c(
                                          "b-form-radio-group",
                                          {
                                            attrs: {
                                              "value-field": "seq",
                                              "text-field": "description",
                                              stacked: "",
                                            },
                                          },
                                          _vm._l(el.choices, function (choice) {
                                            return _c(
                                              "b-form-radio",
                                              {
                                                key: el.id + "_" + choice.seq,
                                                staticClass: "mt-50",
                                                attrs: { value: choice.seq },
                                                on: {
                                                  change:
                                                    _vm.changeElementValue,
                                                },
                                                model: {
                                                  value: el.tempVal,
                                                  callback: function ($$v) {
                                                    _vm.$set(el, "tempVal", $$v)
                                                  },
                                                  expression: "el.tempVal",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(choice.description) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }),
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  el.type === 2
                                    ? [
                                        _c(
                                          "b-form-checkbox-group",
                                          {
                                            attrs: {
                                              "value-field": "seq",
                                              "text-field": "description",
                                              stacked: "",
                                            },
                                          },
                                          _vm._l(el.choices, function (choice) {
                                            return _c(
                                              "b-form-checkbox",
                                              {
                                                key: el.id + "_" + choice.seq,
                                                staticClass: "mt-50",
                                                attrs: { value: choice.seq },
                                                on: {
                                                  change:
                                                    _vm.changeElementValue,
                                                },
                                                model: {
                                                  value: el.tempVal,
                                                  callback: function ($$v) {
                                                    _vm.$set(el, "tempVal", $$v)
                                                  },
                                                  expression: "el.tempVal",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(choice.description) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }),
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  el.type === 3
                                    ? [
                                        _c("b-form-input", {
                                          attrs: { maxlength: el.maxCheck },
                                          on: {
                                            click: _vm.tempClick,
                                            input: function ($event) {
                                              return _vm.filteredValue(el)
                                            },
                                            change: _vm.changeElementValue,
                                          },
                                          model: {
                                            value: el.tempVal,
                                            callback: function ($$v) {
                                              _vm.$set(el, "tempVal", $$v)
                                            },
                                            expression: "el.tempVal",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  el.type === 4
                                    ? [
                                        _c("b-form-textarea", {
                                          attrs: {
                                            rows: el.rows || 5,
                                            maxlength: el.maxCheck,
                                          },
                                          on: {
                                            click: _vm.tempClick,
                                            input: function ($event) {
                                              return _vm.filteredValue(el)
                                            },
                                            change: _vm.changeElementValue,
                                          },
                                          model: {
                                            value: el.tempVal,
                                            callback: function ($$v) {
                                              _vm.$set(el, "tempVal", $$v)
                                            },
                                            expression: "el.tempVal",
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  el.type === 5
                                    ? [
                                        _c(
                                          "b-list-group",
                                          _vm._l(el.choices, function (choice) {
                                            return _c(
                                              "b-list-group-item",
                                              {
                                                key: el.id + "_" + choice.seq,
                                                staticClass:
                                                  "d-flex justify-content-start align-items-center",
                                                attrs: { value: choice.seq },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.clickRank(
                                                      el,
                                                      choice
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                choice.rank
                                                  ? _c(
                                                      "b-badge",
                                                      {
                                                        staticClass:
                                                          "badge-round mr-50",
                                                        attrs: {
                                                          variant: "primary",
                                                          pill: "",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              choice.rank
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(choice.description)
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          }),
                                          1
                                        ),
                                      ]
                                    : _vm._e(),
                                  el.type === 6
                                    ? [
                                        _vm._l(
                                          el.choices,
                                          function (choice, eChIdx) {
                                            return [
                                              _c(
                                                "b-form-group",
                                                {
                                                  key: el.id + "_" + choice.seq,
                                                  attrs: {
                                                    label:
                                                      eChIdx +
                                                      1 +
                                                      ") " +
                                                      choice.description,
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    {
                                                      staticClass:
                                                        "input-group-merge",
                                                      attrs: { append: "%" },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        staticClass:
                                                          "text-right",
                                                        attrs: {
                                                          type: "number",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          }
                                        ),
                                      ]
                                    : _vm._e(),
                                  el.type === 7
                                    ? [
                                        _c("grid-choice", {
                                          attrs: {
                                            "el-seq": el.seq,
                                            "sub-type": el.subType,
                                            choices: el.choices,
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  el.type === 8
                                    ? [
                                        _c("scale-view", {
                                          attrs: { element: el },
                                        }),
                                      ]
                                    : _vm._e(),
                                  el.type === 9 && el.refElement
                                    ? [
                                        _c("piping-view", {
                                          attrs: {
                                            element: el,
                                            "ref-element": el.refElement,
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                  el.type === 10
                                    ? [
                                        _c("div", {
                                          staticClass: "mt-1",
                                          domProps: {
                                            innerHTML: _vm._s(
                                              el.autoDescription
                                            ),
                                          },
                                        }),
                                        _c(
                                          "b-alert",
                                          {
                                            staticClass: "mt-2",
                                            attrs: {
                                              show: "",
                                              variant: "secondary",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "alert-body" },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    "변수(자동제출 표시용)목록: 아래 표시된 변수명([1],[2]..[n])을 제목 또는 설문항목표시문구에 포함하세요."
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-list-group",
                                          [
                                            el.autoType === 21 ||
                                            el.autoType === 22 ||
                                            el.autoType === 23
                                              ? [
                                                  _vm._l(
                                                    el.autos,
                                                    function (auto) {
                                                      return _c(
                                                        "b-list-group-item",
                                                        {
                                                          key: auto.seq,
                                                          staticClass:
                                                            "d-flex justify-content-start align-items-center",
                                                          attrs: {
                                                            value: auto.seq,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-badge",
                                                            {
                                                              staticClass:
                                                                "badge-round mr-50",
                                                              attrs: {
                                                                variant:
                                                                  "light-warning",
                                                                pill: "",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " [" +
                                                                  _vm._s(
                                                                    auto.viewIndex
                                                                  ) +
                                                                  "] "
                                                              ),
                                                            ]
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(auto.codes)
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  el.isAutoEtc
                                                    ? _c(
                                                        "b-list-group-item",
                                                        {
                                                          staticClass:
                                                            "d-flex justify-content-start align-items-center",
                                                        },
                                                        [
                                                          _c(
                                                            "b-badge",
                                                            {
                                                              staticClass:
                                                                "badge-round mr-50",
                                                              attrs: {
                                                                variant:
                                                                  "light-warning",
                                                                pill: "",
                                                              },
                                                            },
                                                            [_vm._v(" [0] ")]
                                                          ),
                                                          _c("span", [
                                                            _vm._v("기타"),
                                                          ]),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ]
                                              : [
                                                  _c(
                                                    "b-list-group-item",
                                                    {
                                                      staticClass:
                                                        "d-flex justify-content-start align-items-center",
                                                    },
                                                    [
                                                      _c(
                                                        "b-badge",
                                                        {
                                                          staticClass:
                                                            "badge-round mr-50",
                                                          attrs: {
                                                            variant:
                                                              "light-warning",
                                                            pill: "",
                                                          },
                                                        },
                                                        [_vm._v(" [1] ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                      ]
                                    : _vm._e(),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
      _c("element-edit-form", {
        attrs: { item: _vm.currElement },
        on: {
          update: _vm.elementEditForm.update,
          remove: _vm.elementEditForm.remove,
        },
        model: {
          value: _vm.activeElement,
          callback: function ($$v) {
            _vm.activeElement = $$v
          },
          expression: "activeElement",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }