var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "cancel-variant": "outline-secondary",
        centered: "",
        "ok-title": "적용",
        "cancel-title": "취소",
        size: "lg",
        title: "입력 항목 편집",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.submit()
        },
        close: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
        cancel: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
      },
      model: {
        value: _vm.showChoiceInputConfig,
        callback: function ($$v) {
          _vm.showChoiceInputConfig = $$v
        },
        expression: "showChoiceInputConfig",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "mt-25" },
        [
          _c(
            "b-col",
            {
              staticClass: "mb-1 d-flex align-items-center justify-content-end",
              attrs: { cols: "12" },
            },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addChoice($event)
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "PlusSquareIcon" },
                  }),
                  _c("span", [_vm._v("항목 추가")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "validation-observer",
        { ref: "formRules" },
        _vm._l(_vm.choices, function (choice, idx) {
          return _c(
            "div",
            { key: choice.seq, attrs: { id: "choice" + idx } },
            [
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center",
                      attrs: { cols: "1" },
                    },
                    [
                      _c("b-badge", { attrs: { variant: "light-secondary" } }, [
                        _vm._v(" " + _vm._s("항목" + (idx + 1)) + " "),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c("validation-provider", {
                        attrs: { name: "항목", rules: "required" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var errors = ref.errors
                                return [
                                  _c("b-form-input", {
                                    attrs: {
                                      state: errors.length > 0 ? false : null,
                                    },
                                    model: {
                                      value: choice.description,
                                      callback: function ($$v) {
                                        _vm.$set(choice, "description", $$v)
                                      },
                                      expression: "choice.description",
                                    },
                                  }),
                                  _c("small", { staticClass: "text-danger" }, [
                                    _vm._v(_vm._s(errors[0])),
                                  ]),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end",
                      attrs: { cols: "1" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "outline-secondary", size: "sm" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.removeChoice(choice)
                            },
                          },
                        },
                        [_c("feather-icon", { attrs: { icon: "Trash2Icon" } })],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }