<!-- 격자형 문항 뷰 -->
<template>
  <div>
    <!-- 단일 선택 -->
    <b-form-radio-group
      v-if="element.subType === 1"
      value-field="seq"
      text-field="description"
      stacked
    >
      <b-form-radio
        v-for="choice in filterPipingChoices(refElement)"
        :key="`${element.id}_${choice.seq}`"
        :value="choice.seq"
        class="mt-50"
      >
        {{ choice.description }}
      </b-form-radio>
    </b-form-radio-group>

    <!-- 복수 선택 -->
    <b-form-checkbox-group
      v-if="element.subType === 2"
      value-field="seq"
      text-field="description"
      stacked
    >
      <b-form-checkbox
        v-for="choice in filterPipingChoices(refElement)"
        :key="`${element.id}_${choice.seq}`"
        :value="choice.seq"
        class="mt-50"
      >
        {{ choice.description }}
      </b-form-checkbox>
    </b-form-checkbox-group>

    <!-- 순위형 -->
    <b-list-group
      v-if="element.subType === 3"
    >
      <b-list-group-item
        v-for="choice in filterPipingChoices(refElement)"
        :key="`${element.id}_${choice.seq}`"
        :value="choice.seq"
        class="d-flex justify-content-start align-items-center"
        @click="clickRank(choice)"
      >
        <b-badge
          v-if="choice.rank"
          variant="primary"
          pill
          class="badge-round mr-50"
        >
          {{ choice.rank }}
        </b-badge>
        <span>{{ choice.description }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { BBadge, BListGroup, BListGroupItem } from 'bootstrap-vue'
import { getCurrentInstance } from '@vue/composition-api'

export default {
  components: {
    BBadge,
    BListGroup,
    BListGroupItem,
  },

  props: {
    element: {
      type: Object,
      required: false,
      default: () => {},
    },
    refElement: {
      type: Object,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const instance = getCurrentInstance()

    const filterPipingChoices = refElement => {
      if (refElement.choices.length === 0) return []

      if (!refElement.tempVal || refElement.tempVal.length === 0) {
        return refElement.choices // 연결 문항에서 선택(체크) 내역이 없다면 전체 리스트를 반환
      }

      return refElement.choices.filter(ch => refElement.tempVal.some(seq => seq === ch.seq))
    }

    const clickRank = choice => {
      const { element, refElement } = props
      const pipingChoices = filterPipingChoices(refElement)

      let answers = pipingChoices.filter(ch => ch.rank > 0).sort((a, b) => a.rank - b.rank)

      if (answers.includes(choice)) {
        answers = answers.filter(answer => answer !== choice)
      } else {
        answers.push(choice)
      }
      // const itemIdx = answers.indexOf(choice)
      // const activeSeqs = []
      // if (itemIdx !== -1) {
      //   answers.splice(itemIdx, 1)
      // } else {
      //   answers.push(choice)
      // }

      pipingChoices.forEach(ch => {
        const targetChoice = ch
        targetChoice.rank = null
      })
      const activeSeqs = answers.map((answer, i) => {
        const matchChoice = pipingChoices.find(ch => ch.seq === answer.seq)
        if (matchChoice) matchChoice.rank = i + 1
        return matchChoice?.seq
      })

      element.tempVal = activeSeqs.filter(Boolean)
      // changeElementValue(activeSeqs)
      instance.proxy.$forceUpdate()
    }

    return {
      filterPipingChoices,
      clickRank,
    }
  },
}
</script>
