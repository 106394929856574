<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="mx-2 my-1">
        <b-row>
          <!-- 설문 타이틀 표시 영역 -->
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start"
          >
            <span>
              {{ paperData.title }}
            </span>
          </b-col>
          <!-- 페이지 제어 버튼 영역 (정렬순서변경, 페이지 삭제 등) -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              v-show="paperData.id"
              size="sm"
              variant="primary"
              @click.prevent="previewPaper"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              <span>미리보기</span>
            </b-button>
            <span
              v-show="readonly"
              style="color: #ea5455"
            >
              [읽기모드] 현재 진행 중인 설문으로 <b>문항정보는 변경할 수 없습니다.</b>
            </span>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
      style="margin-bottom: 5px;"
    >
      <div
        style="padding: 10px 15px 3px 20px;"
      >
        <b-tabs
          v-model="currTabIndex"
          @activate-tab="activateTab"
        >
          <b-tab title="설문 설정" />
          <b-tab
            v-for="(page, idx) in pages"
            :key="'page' + idx"
            :title="`${(idx + 1)} 페이지`"
          />
          <template #tabs-end>
            <b-nav-item
              v-show="!readonly"
              role="presentation"
              @click.prevent="newPage"
            >
              <b>+</b>
            </b-nav-item>
          </template>
        </b-tabs>
      </div>
    </b-card>

    <!-- 설문 페이지 뷰 -->
    <page-view
      :paper-info="paperData"
      :page-id="currPageId"
      :pages="pages"
      :readonly="readonly"
      @remove-page="pageView.removePage"
      @update-paper-info="pageView.updatePaperInfo"
    />

    <div style="position: fixed; bottom: 5%; z-index: 99; right: 60px;">
      <b-button
        v-if="currPageId === 0"
        variant="primary"
        class="btn-search"
        style="position: relative; margin-bottom: 15px;"
        @click.prevent="savePaper"
      >
        설문 설정 저장
      </b-button>
    </div>

    <!-- 설문지 미리보기 -->
    <b-modal
      v-model="showPreview"
      size="xl"
      title="설문지 미리보기"
    >
      <iframe
        ref="previewFrame"
        :src="previewUrl"
        frameborder="0"
        width="1400"
        height="820"
        @load="loadPreview"
      />
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'
import {
  ref, getCurrentInstance, onMounted,
} from '@vue/composition-api'
import {
  BTabs, BTab, BNavItem,
} from 'bootstrap-vue'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'
import PageView from './PageView.vue'

export default {
  components: {
    BTabs,
    BTab,
    BNavItem,

    PageView,
  },

  props: {
  },

  setup() {
    onMounted(() => {
      const route = instance.proxy.$route
      if (route.query && route.query.id) {
        // paper 조회
        fetchPaper(route.query.id)
      }
    })

    const toast = useToast()
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }

      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const readonly = ref(false)
    const paperInitState = {
      id: null,
      title: null,
      description: null,
    }
    const paperData = ref({ ...paperInitState })

    // 페이지(탭) 영역 제어
    const pages = ref([])

    const currPageId = ref(0)
    const currTabIndex = ref(0)

    const pageView = {
      removePage: () => {
        // 페이지 삭제 완료 후 처리
        const pageLen = pages.value.length
        const removeTabIdx = currTabIndex.value - 1
        pages.value.splice(removeTabIdx, 1) // 삭제 된 페이지 탭에서 제거

        // 삭제된 페이지가 마지막 탭인 경우, 이전 탭(페이지)을 가리키도록
        if (removeTabIdx === pageLen - 1) currTabIndex.value -= 1
      },

      updatePaperInfo: rs => {
        paperData.value.title = rs.title
        paperData.value.description = rs.description
      },
    }

    const showPreview = ref(false)
    const previewUrl = `${process.env.VUE_APP_PREVIEW_URL}/#/app/preview-survey`

    const previewPaper = () => {
      if (!paperData.value.id) {
        toast({
          component: ToastificationContent,
          props: {
            title: '생성되지 않은 설문지는 미리보기 할 수 없습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      showPreview.value = true
    }

    const loadPreview = e => {
      if (e.type === 'load') refs.previewFrame.contentWindow.postMessage(paperData.value.id, '*')
    }

    // 탭 전환 이전 이벤트
    const activateTab = tabIdx => {
      currPageId.value = tabIdx === 0 ? 0 : pages.value[(tabIdx - 1)].id
    }

    const fetchPaper = id => {
      axios.get(`/fa/survey-paper/${id}`)
        .then(rs => {
          paperData.value.id = rs.data.id
          paperData.value.title = rs.data.title
          paperData.value.description = rs.data.description
          readonly.value = rs.data.existAnswer

          pages.value = rs.data.pages
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '설문지 정보 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const savePaper = () => {
      if (!paperData.value.title || !paperData.value.description) {
        pushToast('warning', '설문지 제목, 페이지 설명을 작성해 주세요.')
        return
      }
      axios.put('/fa/survey-paper/save', {
        id: paperData.value.id,
        title: paperData.value.title,
        description: paperData.value.description,
      })
        .then(rs => {
          pushToast('success', paperData.value.id === null ? '신규 설문지 생성 완료' : '설문 설정 업데이트 완료')
          if (rs.data.id) {
            paperData.value.id = rs.data.id
          }
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '설문지 생성(또는 정보변경) 과정에서 오류가 발생하였습니다.'))
        })
    }

    // 페이지 추가
    const newPage = () => {
      if (!paperData.value.id) {
        pushToast('warning', '먼저 "설문 설정 저장"을 통해 설문지를 생성해 주세요.')
        return
      }
      axios.post('/fa/survey-paper/new-page', {
        surveyPaperId: paperData.value.id,
        title: null,
        description: null,
      })
        .then(rs => {
          pushToast('success', '신규 페이지 생성')
          if (rs.data.id) {
            // 정상 등록 시 신규 페이지 삽입
            pages.value.push({
              id: rs.data.id,
              title: null,
              description: null,
            })
          }
        })
        .catch(error => {
          pushToast('danger', errorFormatter(error, '신규 페이지 생성 과정에서 오류가 발생하였습니다.'))
        })
    }

    return {
      pages,
      readonly,
      paperData,
      pageView,

      savePaper,

      required,
      newPage,
      activateTab,
      currPageId,
      currTabIndex,

      previewPaper,
      showPreview,
      previewUrl,
      loadPreview,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/views/vertical-layout.scss';
</style>
