var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        {
          staticClass: "element-area",
          attrs: { "border-variant": _vm.active ? "primary" : "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.clickHeader($event)
            },
          },
        },
        [
          !_vm.header.title
            ? [
                _c("label", [_vm._v("[Intro 표시 영역]")]),
                _c("h5", { staticClass: "mt-2" }, [
                  _vm._v(" 제목, 페이지 설명을 입력하려면 클릭하세요. "),
                ]),
              ]
            : _vm._e(),
          _vm.header.title
            ? [
                _c("h4", [_vm._v(" " + _vm._s(_vm.header.title) + " ")]),
                _c("div", {
                  staticClass: "mt-3",
                  domProps: { innerHTML: _vm._s(_vm.header.description) },
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("header-edit-form", {
        attrs: { header: _vm.headerData },
        on: { update: _vm.headerEditForm.update },
        model: {
          value: _vm.showHeaderEdit,
          callback: function ($$v) {
            _vm.showHeaderEdit = $$v
          },
          expression: "showHeaderEdit",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }