var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "survey-item" }, [
    _c("dd", [
      _c("div", { staticClass: "scale-type" }, [
        _c(
          "div",
          { staticClass: "scale-items" },
          _vm._l(_vm.scales, function (scale) {
            return _c(
              "div",
              {
                key: "scale-" + _vm.element.seq + "-" + scale,
                staticClass: "scale-radio-type",
              },
              [
                _c("input", {
                  attrs: {
                    id: "scale-" + _vm.element.seq + "-" + scale,
                    type: "radio",
                    name: "scaleType",
                  },
                }),
                _c(
                  "label",
                  { attrs: { for: "scale-" + _vm.element.seq + "-" + scale } },
                  [_vm._v(_vm._s(scale))]
                ),
              ]
            )
          }),
          0
        ),
      ]),
    ]),
    _c("dd", [
      _c("div", { staticClass: "guide-txt" }, [
        _c("span", { staticClass: "txt-left" }, [
          _vm._v(_vm._s(_vm.element.minDesc)),
        ]),
        _c("span", [_vm._v(_vm._s(_vm.element.medDesc))]),
        _c("span", { staticClass: "txt-right" }, [
          _vm._v(_vm._s(_vm.element.maxDesc)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }