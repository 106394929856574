<!-- 격자형 문항 뷰 -->
<template>
  <div class="survey-item">
    <dd>
      <div class="scale-type">
        <div class="scale-items">
          <div
            v-for="scale in scales"
            :key="`scale-${element.seq}-${scale}`"
            class="scale-radio-type"
          >
            <input
              :id="`scale-${element.seq}-${scale}`"
              type="radio"
              name="scaleType"
            >
            <label :for="`scale-${element.seq}-${scale}`">{{ scale }}</label>
          </div>
        </div>
      </div>
    </dd>
    <dd>
      <div class="guide-txt">
        <span class="txt-left">{{ element.minDesc }}</span>
        <span>{{ element.medDesc }}</span>
        <span class="txt-right">{{ element.maxDesc }}</span>
      </div>
    </dd>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
  },

  props: {
    element: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  setup(props) {
    const scales = ref([])

    const makeScales = () => {
      const { element } = props

      const { scaleLevel, minValue } = element

      scales.value = Array.from({ length: scaleLevel }, (_, i) => minValue + i)
    }

    watch(() => props.element, () => {
      makeScales()
    }, { immediate: true })

    return {
      scales,
    }
  },
}
</script>

<style>
.survey-item .scale-type .scale-items {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.survey-item .scale-type .scale-items .scale-radio-type {
  width: 100%;
}

.survey-item .scale-type .scale-items .scale-radio-type input {
  display: none;
}

.survey-item .scale-type .scale-items .scale-radio-type input + label {
  display: block;
  width: 100%;
  padding: 12px 15px;
  border-radius: 4px;
  border: 1px solid #DDD;
  background: #DDD;
  color: #555;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.45px;
  text-align: center;
  height: 48px;
}

.survey-item .scale-type .scale-items .scale-radio-type input:checked + label {
  background: #7367f0;
  color: #FFF;
  font-weight: 700;
}

.survey-item .guide-txt {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 20px;
}

.survey-item .guide-txt span {
  text-align: center;
  width: 100px;
  color: #555;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.39px;
}

.txt-left {
  text-align: left !important;
}

.txt-right {
  text-align: right !important;
}
</style>
