<!-- [팝업] 선택 항목 구성 -->
<template>
  <b-modal
    v-model="showEditor"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    :title="title"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <div
      v-if="description"
      class="p-1"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
          class="d-flex justify-content-center"
        >
          <h5>{{ description }}</h5>
        </b-col>
      </b-row>
      <!-- <b-row class="my-1">
        <b-col
          cols="12"
          md="12"
          class="d-flex justify-content-center"
        >
          콘텐츠 내용 입력입니다.
        </b-col>
      </b-row> -->
    </div>
    <quill-editor
      id="survey-element-description"
      v-model="bindText"
      :value="text"
    />
  </b-modal>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    quillEditor,
  },

  props: {
    showEditor: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },

  setup(props, { emit }) {
    watch(() => props.text, newVal => {
      bindText.value = newVal.indexOf('empty') !== -1 ? '' : newVal
    })

    const bindText = ref('')

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      emit('close', bindText.value)
    }

    return {
      bindText,
      close,
      submit,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss">
.ql-editor {
  height: 300px;
}
</style>
