var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.element.subType === 1
        ? _c(
            "b-form-radio-group",
            {
              attrs: {
                "value-field": "seq",
                "text-field": "description",
                stacked: "",
              },
            },
            _vm._l(_vm.filterPipingChoices(_vm.refElement), function (choice) {
              return _c(
                "b-form-radio",
                {
                  key: _vm.element.id + "_" + choice.seq,
                  staticClass: "mt-50",
                  attrs: { value: choice.seq },
                },
                [_vm._v(" " + _vm._s(choice.description) + " ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.element.subType === 2
        ? _c(
            "b-form-checkbox-group",
            {
              attrs: {
                "value-field": "seq",
                "text-field": "description",
                stacked: "",
              },
            },
            _vm._l(_vm.filterPipingChoices(_vm.refElement), function (choice) {
              return _c(
                "b-form-checkbox",
                {
                  key: _vm.element.id + "_" + choice.seq,
                  staticClass: "mt-50",
                  attrs: { value: choice.seq },
                },
                [_vm._v(" " + _vm._s(choice.description) + " ")]
              )
            }),
            1
          )
        : _vm._e(),
      _vm.element.subType === 3
        ? _c(
            "b-list-group",
            _vm._l(_vm.filterPipingChoices(_vm.refElement), function (choice) {
              return _c(
                "b-list-group-item",
                {
                  key: _vm.element.id + "_" + choice.seq,
                  staticClass:
                    "d-flex justify-content-start align-items-center",
                  attrs: { value: choice.seq },
                  on: {
                    click: function ($event) {
                      return _vm.clickRank(choice)
                    },
                  },
                },
                [
                  choice.rank
                    ? _c(
                        "b-badge",
                        {
                          staticClass: "badge-round mr-50",
                          attrs: { variant: "primary", pill: "" },
                        },
                        [_vm._v(" " + _vm._s(choice.rank) + " ")]
                      )
                    : _vm._e(),
                  _c("span", [_vm._v(_vm._s(choice.description))]),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }