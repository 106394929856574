var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "cancel-variant": "outline-secondary",
        centered: "",
        "ok-title": "적용",
        "cancel-title": "취소",
        size: "lg",
        title: "하위 문항 선택",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.submit()
        },
        close: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
        cancel: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
      },
      model: {
        value: _vm.showElementGroupConfig,
        callback: function ($$v) {
          _vm.showElementGroupConfig = $$v
        },
        expression: "showElementGroupConfig",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "m-1" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-start",
              attrs: { cols: "12" },
            },
            [_c("h5", [_vm._v("하위 문항을 선택하세요")])]
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "m-1" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-start",
              attrs: { cols: "12" },
            },
            [
              _c("h5", [
                _vm._v(
                  "※ 하위 문항(시작문항-종료문항) 지정 시 설문 페이지에서 그룹제목과 하위문항이 묶여진 것처럼 스타일이 적용됩니다. (*그룹제목과 하위문항 사이의 연결 관계는 없습니다.)"
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "validation-observer",
        { ref: "formRules" },
        [
          _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center",
                  attrs: { cols: "2" },
                },
                [
                  _c("b-badge", { attrs: { variant: "light-secondary" } }, [
                    _vm._v(" 시작 문항 "),
                  ]),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "10" } },
                [
                  _c("validation-provider", {
                    attrs: { name: "시작 문항", rules: "required" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var errors = ref.errors
                          return [
                            _c("v-select", {
                              attrs: {
                                options: _vm.groupElements,
                                reduce: function (option) {
                                  return option.seq
                                },
                                placeholder: "문항 선택",
                                disabled: "",
                              },
                              model: {
                                value: _vm.groupStartElementSeq,
                                callback: function ($$v) {
                                  _vm.groupStartElementSeq = $$v
                                },
                                expression: "groupStartElementSeq",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(_vm._s(errors[0])),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-1" },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center",
                  attrs: { cols: "2" },
                },
                [
                  _c("b-badge", { attrs: { variant: "light-secondary" } }, [
                    _vm._v(" 종료 문항 "),
                  ]),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "10" } },
                [
                  _c("validation-provider", {
                    attrs: {
                      name: "종료 문항",
                      rules: {
                        required: true,
                        notEqual: _vm.groupStartElementSeq,
                        after: _vm.groupStartElementSeq,
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var failedRules = ref.failedRules
                          return [
                            _c("v-select", {
                              attrs: {
                                options: _vm.groupEndElements(),
                                reduce: function (option) {
                                  return option.seq
                                },
                                placeholder: "문항 선택",
                              },
                              model: {
                                value: _vm.groupEndElementSeq,
                                callback: function ($$v) {
                                  _vm.groupEndElementSeq = $$v
                                },
                                expression: "groupEndElementSeq",
                              },
                            }),
                            _c("small", { staticClass: "text-danger" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    failedRules.hasOwnProperty("required")
                                      ? "[종료 문항]는(은) 필수 입력입니다."
                                      : failedRules.hasOwnProperty("notEqual")
                                      ? "[시작 문항]과(와) [종료 문항]는(은) 동일하게 선택할 수 없습니다."
                                      : failedRules.hasOwnProperty("after")
                                      ? "[종료 문항]는(은) [시작 문항]보다 이전 문항을 선택할 수 없습니다."
                                      : ""
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }