var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "mx-2 my-1" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start",
                    attrs: { cols: "12", md: "8" },
                  },
                  [
                    _c("span", [
                      _vm._v(" " + _vm._s(_vm.paperData.title) + " "),
                    ]),
                  ]
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "4" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.paperData.id,
                            expression: "paperData.id",
                          },
                        ],
                        attrs: { size: "sm", variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.previewPaper($event)
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-50",
                          attrs: { icon: "EyeIcon" },
                        }),
                        _c("span", [_vm._v("미리보기")]),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.readonly,
                            expression: "readonly",
                          },
                        ],
                        staticStyle: { color: "#ea5455" },
                      },
                      [
                        _vm._v(" [읽기모드] 현재 진행 중인 설문으로 "),
                        _c("b", [_vm._v("문항정보는 변경할 수 없습니다.")]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { staticStyle: { "margin-bottom": "5px" }, attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px 15px 3px 20px" } },
            [
              _c(
                "b-tabs",
                {
                  on: { "activate-tab": _vm.activateTab },
                  scopedSlots: _vm._u([
                    {
                      key: "tabs-end",
                      fn: function () {
                        return [
                          _c(
                            "b-nav-item",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.readonly,
                                  expression: "!readonly",
                                },
                              ],
                              attrs: { role: "presentation" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.newPage($event)
                                },
                              },
                            },
                            [_c("b", [_vm._v("+")])]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.currTabIndex,
                    callback: function ($$v) {
                      _vm.currTabIndex = $$v
                    },
                    expression: "currTabIndex",
                  },
                },
                [
                  _c("b-tab", { attrs: { title: "설문 설정" } }),
                  _vm._l(_vm.pages, function (page, idx) {
                    return _c("b-tab", {
                      key: "page" + idx,
                      attrs: { title: idx + 1 + " 페이지" },
                    })
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
      _c("page-view", {
        attrs: {
          "paper-info": _vm.paperData,
          "page-id": _vm.currPageId,
          pages: _vm.pages,
          readonly: _vm.readonly,
        },
        on: {
          "remove-page": _vm.pageView.removePage,
          "update-paper-info": _vm.pageView.updatePaperInfo,
        },
      }),
      _c(
        "div",
        {
          staticStyle: {
            position: "fixed",
            bottom: "5%",
            "z-index": "99",
            right: "60px",
          },
        },
        [
          _vm.currPageId === 0
            ? _c(
                "b-button",
                {
                  staticClass: "btn-search",
                  staticStyle: {
                    position: "relative",
                    "margin-bottom": "15px",
                  },
                  attrs: { variant: "primary" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.savePaper($event)
                    },
                  },
                },
                [_vm._v(" 설문 설정 저장 ")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { size: "xl", title: "설문지 미리보기" },
          model: {
            value: _vm.showPreview,
            callback: function ($$v) {
              _vm.showPreview = $$v
            },
            expression: "showPreview",
          },
        },
        [
          _c("iframe", {
            ref: "previewFrame",
            attrs: {
              src: _vm.previewUrl,
              frameborder: "0",
              width: "1400",
              height: "820",
            },
            on: { load: _vm.loadPreview },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }