var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        "cancel-variant": "outline-secondary",
        centered: "",
        "ok-title": "적용",
        "cancel-title": "취소",
        size: "lg",
        title: "자동제출 항목 편집",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.submit()
        },
        close: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
        cancel: function ($event) {
          $event.preventDefault()
          return _vm.close()
        },
      },
      model: {
        value: _vm.showAutoConfig,
        callback: function ($$v) {
          _vm.showAutoConfig = $$v
        },
        expression: "showAutoConfig",
      },
    },
    [
      _c(
        "b-row",
        { staticClass: "m-1" },
        [
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-start",
              attrs: { cols: "12" },
            },
            [_c("h5", [_vm._v("환자% (약품): 약품코드 입력")])]
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-start",
              attrs: { cols: "12" },
            },
            [_c("h5", [_vm._v("환자% (상병): 상병코드 입력")])]
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-start",
              attrs: { cols: "12" },
            },
            [
              _c("h5", [
                _vm._v(
                  "환자% (검사): 검사코드 입력 및 결과수치 범위 지정이 필요한 경우 지정(선택사항)"
                ),
              ]),
            ]
          ),
          _c(
            "b-col",
            {
              staticClass: "d-flex justify-content-start mt-50",
              attrs: { cols: "12" },
            },
            [
              _c("h5", [
                _vm._v("좌측에 표기되는 문구 값([1],[2]..[n])을 "),
                _c("u", [_vm._v('"설문항목표시문구"')]),
                _vm._v(" 편집 시에 사용"),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-25 pr-50" },
        [
          _c(
            "b-col",
            {
              staticClass: "mb-1 d-flex align-items-center justify-content-end",
              attrs: { cols: "12" },
            },
            [
              _c(
                "b-button",
                {
                  attrs: { variant: "outline-primary", size: "sm" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addChoice()
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    staticClass: "mr-50",
                    attrs: { icon: "PlusSquareIcon" },
                  }),
                  _c("span", [_vm._v("항목 추가")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "validation-observer",
        { ref: "formRules" },
        [
          _c(
            "b-row",
            { staticClass: "mt-25" },
            [
              _c("b-col", { attrs: { md: "1" } }),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center",
                  attrs: { md: _vm.autoType === 22 ? 5 : 9 },
                },
                [_vm._v(" 입력 칸에 여러개의 코드 입력 시 콤마(,)로 구분 ")]
              ),
              _vm.autoType === 22
                ? _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-center",
                      attrs: { md: "4" },
                    },
                    [_vm._v(" 검사 결과수치 범위 (소수점5자리까지) ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._l(_vm.autos, function (auto, idx) {
            return _c(
              "b-row",
              {
                key: auto.seq,
                staticClass: "mt-25",
                attrs: { id: "auto" + idx },
              },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-center",
                    attrs: { cols: "12", md: "1" },
                  },
                  [
                    _c("b-badge", { attrs: { variant: "light-secondary" } }, [
                      _vm._v(" " + _vm._s("[" + (idx + 1) + "]") + " "),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { cols: "12", md: _vm.autoType === 22 ? 5 : 9 } },
                  [
                    _c("validation-provider", {
                      attrs: { name: "코드 입력", rules: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (ref) {
                              var errors = ref.errors
                              return [
                                _c("b-form-input", {
                                  attrs: {
                                    state: errors.length > 0 ? false : null,
                                  },
                                  model: {
                                    value: auto.codes,
                                    callback: function ($$v) {
                                      _vm.$set(auto, "codes", $$v)
                                    },
                                    expression: "auto.codes",
                                  },
                                }),
                                _c("small", { staticClass: "text-danger" }, [
                                  _vm._v(_vm._s(errors[0])),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
                _vm.autoType === 22
                  ? _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-start",
                        attrs: { cols: "12", md: "4" },
                      },
                      [
                        _c("b-form-input", {
                          attrs: { type: "number" },
                          model: {
                            value: auto.rangeMin,
                            callback: function ($$v) {
                              _vm.$set(auto, "rangeMin", $$v)
                            },
                            expression: "auto.rangeMin",
                          },
                        }),
                        _c("label", { staticClass: "ml-25 mr-50" }, [
                          _vm._v("~"),
                        ]),
                        _c("b-form-input", {
                          attrs: { type: "number" },
                          model: {
                            value: auto.rangeMax,
                            callback: function ($$v) {
                              _vm.$set(auto, "rangeMax", $$v)
                            },
                            expression: "auto.rangeMax",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-danger", size: "sm" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeChoice(auto)
                          },
                        },
                      },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-25",
                          attrs: { icon: "XIcon" },
                        }),
                        _c("span", [_vm._v("삭제")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }