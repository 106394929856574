<template>
  <div>
    <b-card
      :border-variant="(active ? 'primary' : '')"
      class="element-area"
      @click.prevent="clickHeader"
    >
      <template v-if="!header.title">
        <label>[Intro 표시 영역]</label>
        <h5 class="mt-2">
          제목, 페이지 설명을 입력하려면 클릭하세요.
        </h5>
      </template>
      <template v-if="header.title">
        <h4>
          {{ header.title }}
        </h4>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="mt-3"
          v-html="header.description"
        />
      </template>
    </b-card>

    <header-edit-form
      v-model="showHeaderEdit"
      :header="headerData"
      @update="headerEditForm.update"
    />
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import HeaderEditForm from './HeaderEditForm.vue'

export default {
  components: {
    HeaderEditForm,
  },

  props: {
    header: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
  },

  setup(props, { emit }) {
    watch(() => props.header, () => {
    }, { immediate: true })

    watch(() => props.active, newVal => {
      if (!newVal) showHeaderEdit.value = false
    })

    const elements = ref([])

    const showHeaderEdit = ref(false)
    const headerInitState = {
      title: '',
      description: '',
    }
    const headerData = ref({ ...headerInitState })
    const headerEditForm = {
      call: () => {
        const { header } = props
        headerData.value = {
          title: header.title,
          description: header.description || '',
        }
        showHeaderEdit.value = true
      },

      update: rs => {
        emit('update-header', rs)
      },
    }

    const clickHeader = () => {
      headerEditForm.call()

      emit('click-header')
    }

    return {
      elements,

      headerData,
      clickHeader,
      headerEditForm,
      showHeaderEdit,
    }
  },
}
</script>

<style lang="scss">
.element-area {
  cursor: pointer;
}
</style>
