<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      no-header
      right
      :visible="showElementEdit"
      @change="(val) => $emit('update:show-element-edit-form', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <div>
            문항 정보 변경
          </div>
          <div>
            <feather-icon
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove', item); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-form-group
              label="문항 유형"
              label-for="survey-element-type"
            >
              <h5>{{ item.typeName }}</h5>
            </b-form-group>

            <!-- 문항 제목 (공통) -->
            <b-form-group
              label="문항 제목"
              label-for="survey-element-title"
            >
              <validation-provider
                #default="{ errors }"
                name="문항 제목"
                rules="required"
              >
                <b-form-input
                  id="survey-element-title"
                  v-model="item.title"
                  autofocus
                  :state="errors.length > 0 ? false : null"
                  placeholder="문항 제목(질문 내용) 입력"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- 입력 필수/선택 여부 (공통: 그룹핑 유형만 제외)-->
            <template v-if="item.type !== 50">
              <b-form-group
                label="필수 여부"
                label-for="survey-element-required"
              >
                <b-form-radio-group
                  id="survey-element-required"
                  v-model="item.isRequired"
                  :options="codes.required"
                  value-field="code"
                  text-field="label"
                />
              </b-form-group>
            </template>

            <!-- 최소 선택 수 / 최대 선택 수 (선택[복수],순위형) -->
            <template v-if="item.type === 2 || item.type === 5">
              <b-form-group
                label="최소 선택수"
                label-for="survey-element-choice-min-check"
              >
                <b-form-input
                  id="survey-element-choice-min-check"
                  v-model="item.minCheck"
                  type="number"
                />
              </b-form-group>
              <b-form-group
                label="최대 선택수"
                label-for="survey-element-choice-max-check"
              >
                <b-form-input
                  id="survey-element-choice-max-check"
                  v-model="item.maxCheck"
                  type="number"
                />
              </b-form-group>
            </template>

            <!-- 선택 항목 (선택[단일],선택[복수],순위형) 구성 -->
            <template v-if="item.type === 1 || item.type === 2 || item.type === 5">
              <b-form-group
                label="선택 항목"
                label-for="survey-element-select-items"
              >
                <b-input-group>
                  <b-form-input
                    id="survey-element-select-items"
                    :value="item.choices ? `${item.choices.length}개 항목` : '0개 항목'"
                    disabled
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click.prevent="openChoiceConfig(item.type)"
                    >
                      변경
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </template>

            <!-- 설문비대상종료 메시지 -->
            <template v-if="item.type === 1 && item.isFinishChoice">
              <b-form-group
                label="설문 비대상 종료 메시지"
                label-for="survey-element-finish-message"
              >
                <b-input-group>
                  <b-form-input
                    id="survey-element-finish-message"
                    :value="item.finishMessage ? item.finishMessage : '기본 비대상 종료 메시지로 안내'"
                    disabled
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click.prevent="openEditor(
                        'finishMessage',
                        item.finishMessage,
                        '비대상 종료 메시지 변경',
                        '변경할 메시지 내용을 아래 입력 후 적용해 주세요. 메시지 팝업 사이즈로 인해 의도치 않은 줄바꿈이 발생할 수 있으므로 적용 후 테스트 환경에서 확인하여 주시기 바랍니다.'
                      )"
                    >
                      변경
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </template>

            <!-- 보기 순서 랜덤 여부 -->
            <template v-if="item.type === 1 || item.type === 2 || item.type === 5">
              <b-form-group
                label="보기 순서 랜덤 여부"
                label-for="survey-element-random-order"
              >
                <b-form-radio-group
                  id="survey-element-random-order"
                  v-model="item.isRandomOrder"
                  :options="codes.randomOrder"
                  value-field="code"
                  text-field="label"
                />
              </b-form-group>
            </template>

            <!-- 답변 형식(문자만/숫자만) (텍스트[한줄]) -->
            <template v-if="item.type === 3">
              <b-form-group
                label="답변 형식"
                label-for="survey-element-text-type"
              >
                <v-select
                  id="survey-element-text-type"
                  v-model="item.textType"
                  :options="codes.textType"
                  placeholder="전체"
                  :reduce="option => option.code"
                  @input="changeTextType(item)"
                />
              </b-form-group>
            </template>

            <!-- 입력범위 (텍스트[한줄]이면서 '숫자만' 입력일 때 표시) -->
            <template v-if="item.type === 3 && item.textType === 2">
              <b-form-group
                label="입력범위"
                label-for="survey-element-min-check"
              >
                <b-form-input
                  id="survey-element-min-check"
                  v-model="item.minCheck"
                  type="number"
                  style="width: 45%; display: inline-block;"
                />
                <label class="ml-50 mr-50">~</label>
                <b-form-input
                  id="survey-element-max-check"
                  v-model="item.maxCheck"
                  type="number"
                  style="width: 45%; display: inline-block;"
                />
              </b-form-group>
            </template>

            <!-- 입력란 높이(라인 수) (텍스트[여러줄]) -->
            <template v-if="item.type === 4">
              <b-form-group
                label="입력란 높이(라인수)"
                label-for="survey-element-rows"
              >
                <b-form-input
                  id="survey-element-rows"
                  v-model="item.rows"
                  type="number"
                />
              </b-form-group>
            </template>

            <!-- 최소 글자 수 / 최대 글자 수 (텍스트[여러줄]) -->
            <template v-if="item.type === 4">
              <b-form-group
                label="최소 글자수"
                label-for="survey-element-text-min-check"
              >
                <b-form-input
                  id="survey-element-text-min-check"
                  v-model="item.minCheck"
                  type="number"
                />
              </b-form-group>
              <b-form-group
                label="최대 글자수"
                label-for="survey-element-text-max-check"
              >
                <b-form-input
                  id="survey-element-text-max-check"
                  v-model="item.maxCheck"
                  type="number"
                />
              </b-form-group>
            </template>

            <!-- 표시 문항 연결 (텍스트[한줄],텍스트[여러줄]) -->
            <!-- <template v-if="item.type === 3 || item.type === 4">
              <b-form-group
                label="표시 문항 연결"
                label-for="survey-element-input-show-elements"
              >
                <v-select
                  v-model="item.inputShowElementList"
                  :options="allElements"
                  :reduce="option => option.seq"
                  multiple
                  placeholder="입력 시 표시할 문항 선택(옵션)"
                />
              </b-form-group>
            </template> -->

            <!-- 입력항목 (비율합계형) 구성 -->
            <template v-if="item.type === 6">
              <b-form-group
                label="입력 항목"
                label-for="survey-element-select-input-items"
              >
                <b-input-group>
                  <b-form-input
                    id="survey-element-select-input-items"
                    :value="item.choices ? `${item.choices.length}개 항목` : '0개 항목'"
                    disabled
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click.prevent="openChoiceInputConfig(item.type)"
                    >
                      변경
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </template>

            <!-- 비율 합계 (비율합계형) -->
            <template v-if="item.type === 6">
              <validation-provider
                #default="{ errors }"
                name="비율 합계"
                rules="required"
              >
                <b-form-group
                  label="비율 합계"
                  label-for="survey-element-sum-check"
                >
                  <b-form-input
                    id="survey-element-sum-check"
                    v-model="item.sumCheck"
                    type="number"
                    placeholder="숫자 입력"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </template>

            <!-- 문항 타입 (격자형, 파이핑형)-->
            <template v-if="item.type === 7 || item.type === 9">
              <b-form-group
                label="문항 타입"
                label-for="survey-element-item-sub-type"
              >
                <b-form-radio-group
                  id="survey-element-item-sub-type"
                  v-model="item.subType"
                  :options="filteredItemSubType(item.type)"
                  value-field="code"
                  text-field="label"
                />
              </b-form-group>
            </template>

            <!-- 선택 항목 (격자형) 구성 -->
            <template v-if="item.type === 7">
              <b-form-group
                label="선택 항목"
                label-for="survey-element-select-grid-items"
              >
                <b-input-group>
                  <b-form-input
                    id="survey-element-select-grid-items"
                    :value="item.choices ? choiceGridDisplayCount(item.choices) : '0개 항목'"
                    disabled
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click.prevent="openChoiceGridConfig(item.type)"
                    >
                      변경
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </template>

            <!-- 척도단계, 시작점수, 척도표시 (척도형) -->
            <template v-if="item.type === 8">
              <b-form-group
                label="척도 단계"
                label-for="survey-element-scale-level"
              >
                <validation-provider
                  #default="{ errors }"
                  name="척도 단계"
                  rules="required"
                >
                  <v-select
                    id="survey-element-scale-level"
                    v-model="item.scaleLevel"
                    :options="codes.scaleLevel"
                    :reduce="option => option.code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="시작 점수"
                label-for="survey-element-scale-min-value"
              >
                <validation-provider
                  #default="{ errors }"
                  name="시작 점수"
                  rules="required"
                >
                  <v-select
                    id="survey-element-scale-min-value"
                    v-model="item.minValue"
                    :options="codes.minValue"
                    :reduce="option => option.code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="척도 표시(선택)"
              >
                <b-form-input
                  id="survey-element-scale-min-desc"
                  v-model="item.minDesc"
                  type="text"
                  placeholder="좌측 문구 입력"
                />
                <b-form-input
                  id="survey-element-scale-med-desc"
                  v-model="item.medDesc"
                  type="text"
                  placeholder="중앙 문구 입력"
                  class="mt-50"
                />
                <b-form-input
                  id="survey-element-scale-max-desc"
                  v-model="item.maxDesc"
                  type="text"
                  placeholder="우측 문구 입력"
                  class="mt-50"
                />
              </b-form-group>
            </template>

            <!-- 불러올 문항(1개 선택) (파이핑형) -->
            <template v-if="item.type === 9">
              <b-form-group
                label="불러올 문항(1개 선택)"
                label-for="survey-element-ref-element-id"
              >
                <validation-provider
                  #default="{ errors }"
                  name="불러올 문항"
                  rules="required"
                >
                  <v-select
                    v-model="item.refElementId"
                    :options="filterPipingElements(item.prevElements)"
                    :reduce="option => option.id"
                    placeholder="불러올 문항"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>

            <!-- 그룹핑 하위 문항 (그룹핑) 구성 -->
            <template v-if="item.type === 50">
              <b-form-group
                label="하위 문항"
                label-for="survey-element-group-items"
              >
                <b-input-group>
                  <b-form-input
                    id="survey-element-group-items"
                    :value="item.groupSubElementCount ? `${item.groupSubElementCount}개 항목` : '0개 항목'"
                    disabled
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click.prevent="openElementGroupConfig"
                    >
                      변경
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </template>

            <!-- 설명 문구 (공통) -->
            <b-form-group
              label="설명 문구(*문항 제목 아래에 질문을 설명하기 위한 표시 문구)"
              label-for="survey-element-description"
            >
              <tinymce-editor
                id="survey-element-description"
                v-model="item.description"
              />
            </b-form-group>

            <!-- 자동 제출 전용 요소 -->
            <template v-if="item.type === 100">
              <b-row style="border-top: 1px solid #ebe9f1;" />
              <b-alert
                show
                class="mt-2"
                variant="dark"
              >
                <div class="alert-body">
                  <span>* 자동제출 유형 설정</span>
                </div>
              </b-alert>
              <b-row
                class="mt-1"
              >
                <b-col>
                  <b-form-group
                    label="조회시작일"
                    label-for="survey-element-start-date"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="조회시작일"
                      :rules="{ required: true, before: item.endDate }"
                    >
                      <b-form-datepicker
                        id="survey-element-start-date"
                        v-model="item.startDate"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="조회종료일"
                    label-for="survey-element-end-date"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="조회종료일"
                      :rules="{ required: true, after: item.startDate }"
                    >
                      <b-form-datepicker
                        id="survey-element-end-date"
                        v-model="item.endDate"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group
                label="상병코드"
                label-for="survey-element-diseases"
              >
                <validation-provider
                  #default="{ errors }"
                  name="상병코드"
                  :rules="{ requiredIf: (!item.drugs) }"
                >
                  <b-input-group>
                    <b-form-input
                      id="survey-element-diseases"
                      :value="item.diseases"
                      :state="errors.length > 0 ? false : null"
                      readonly
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click.prevent="openEditor(
                          'diseases',
                          item.diseases,
                          '상병코드 수정',
                          '항목 당 ,(콤마)로 구분합니다. 코드 뒤에 %(퍼센트)를 붙여 다중 검색이 가능합니다.'
                        )"
                      >
                        변경
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">
                    {{ errors.length > 0 ? '상병코드 또는 청구코드 중 하나는 반드시 입력되어야 합니다.' : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="청구코드(약품,검사)"
                label-for="survey-element-drugs"
              >
                <validation-provider
                  #default="{ errors }"
                  name="청구코드(약품,검사)"
                  :rules="{ requiredIf: (!item.diseases) }"
                >
                  <b-input-group>
                    <b-form-input
                      id="survey-element-drugs"
                      :value="item.drugs"
                      :state="errors.length > 0 ? false : null"
                      readonly
                    />
                    <b-input-group-append>
                      <b-button
                        variant="outline-primary"
                        @click.prevent="openEditor(
                          'drugs',
                          item.drugs,
                          '청구코드(약품,검사) 수정',
                          '항목 당 ,(콤마)로 구분합니다.'
                        )"
                      >
                        변경
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">
                    {{ errors.length > 0 ? '상병코드 또는 청구코드 중 하나는 반드시 입력되어야 합니다.' : '' }}
                  </small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="자동제출 유형"
                label-for="survey-element-auto-type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="자동제출 유형"
                  rules="required"
                >
                  <v-select
                    id="survey-element-auto-type"
                    v-model="item.autoType"
                    :options="codes.autoType"
                    placeholder="전체"
                    :reduce="option => option.code"
                    @input="changeAutoType()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="설문항목 구성(환자% 유형만 해당)"
                label-for="survey-element-select-autos"
              >
                <b-input-group>
                  <b-form-input
                    id="survey-element-select-autos"
                    :value="item.autos ? `${item.autos.length}개 항목` : '0개 항목'"
                    disabled
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click.prevent="openAutoConfig()"
                    >
                      변경
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <b-form-group
                label="설문항목 기타포함여부"
                label-for="survey-element-required"
              >
                <b-form-checkbox
                  id="survey-element-is-auto-etc"
                  v-model="item.isAutoEtc"
                  :disabled="!(item.autoType === 20 || item.autoType === 21 || item.autoType === 22)"
                />
              </b-form-group>
              <b-form-group
                label="설문항목 표시문구"
                label-for="survey-element-auto-description"
              >
                <b-input-group>
                  <b-form-input
                    id="survey-element-auto-description"
                    :value="item.autoDescription"
                    readonly
                  />
                  <b-input-group-append>
                    <b-button
                      variant="outline-primary"
                      @click.prevent="openRichEditor(
                        'autoDescription',
                        item.autoDescription,
                        '설문항목 표시문구 편집',
                        '입력한 표시문구([1],[2]..[n])는 이후 설문 답변시에 집계(계산) 결과 값으로 치환됩니다.'
                      )"
                    >
                      변경
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </template>
            <!-- 자동 제출 전용 요소 End. -->

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-col
                cols="6"
                class="d-flex align-items-center justify-content-start"
              >
                <!-- <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                >
                  초기화
                </b-button> -->
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="hide();"
                >
                  닫기
                </b-button>
              </b-col>
              <b-col
                cols="6"
                class="d-flex align-items-center justify-content-end"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                >
                  적용(반영)
                </b-button>
              </b-col>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
    <!-- Textarea 형식 입력 팝업 -->
    <editor-popup
      :show-editor="showEditor"
      :text="editorText"
      :title="editorTitle"
      :description="editorDesc"
      @close="editorConfig.close"
    />
    <!-- Rich Text Editor 형식 입력 팝업 -->
    <rich-editor-popup
      :show-editor="showRichEditor"
      :text="richText"
      :title="richTitle"
      :description="richDesc"
      @close="richConfig.close"
    />
    <!-- 자동제출 유형 보기 편집 팝업 -->
    <element-auto-config
      :show-auto-config="showAutoConfig"
      :autos="autos"
      :auto-type="autoType"
      @close="autoConfig.close"
    />
    <!-- 선택(단일,복수,순위)형 보기 편집 팝업 -->
    <element-choice-config
      :show-choice-config="showChoiceConfig"
      :choices="choices"
      :next-pages="item.nextPages"
      :next-elements="item.nextElements"
      :item-type="choiceConfig.itemType"
      @close="choiceConfig.close"
    />
    <!-- 격자형 입력 항목 편집 팝업 -->
    <element-choice-grid-config
      :show-choice-grid-config="showChoiceGridConfig"
      :choices="gridChoices"
      :item-type="choiceGridConfig.itemType"
      @close="choiceGridConfig.close"
    />
    <!-- 비율합계형 입력 항목 편집 팝업 -->
    <element-choice-input-config
      :show-choice-input-config="showChoiceInputConfig"
      :choices="inputChoices"
      :item-type="choiceInputConfig.itemType"
      @close="choiceInputConfig.close"
    />
    <!-- 그룹핑 하위문항 항목 편집 팝업 -->
    <element-group-config
      :show-element-group-config="showElementGroupConfig"
      :group-elements="item.groupElements"
      :sub-element-count="item.groupSubElementCount"
      @close="elementGroupConfig.close"
    />
  </div>
</template>

<script>
import axios from '@axios'
import { ref, onMounted } from '@vue/composition-api'
import {
  BAlert, BSidebar, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import TinymceEditor from '@/views/TinyMCE.vue'
import {
  required, after, before, requiredIf,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { clone } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import EditorPopup from '@/views/apps/popup/TextEditor.vue'
import RichEditorPopup from '@/views/apps/popup/RichTextEditor.vue'
import ElementAutoConfig from './ElementAutoConfig.vue'
import ElementChoiceConfig from './ElementChoiceConfig.vue'
import ElementChoiceGridConfig from './ElementChoiceGridConfig.vue'
import ElementChoiceInputConfig from './ElementChoiceInputConfig.vue'
import ElementGroupConfig from './ElementGroupConfig.vue'

export default {
  components: {
    vSelect,
    BAlert,
    BSidebar,
    BInputGroup,
    BInputGroupAppend,
    TinymceEditor,
    ValidationProvider,
    ValidationObserver,

    EditorPopup,
    RichEditorPopup,
    ElementAutoConfig,
    ElementChoiceConfig,
    ElementChoiceGridConfig,
    ElementChoiceInputConfig,
    ElementGroupConfig,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'showElementEdit',
    event: 'update:show-element-edit-form',
  },
  props: {
    showElementEdit: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    onMounted(() => {
      fetchCodes()
    })

    const toast = useToast()

    const codes = ref({
      required: [
        { label: '필수입력', code: true },
        { label: '선택입력', code: false },
      ],
      randomOrder: [
        { label: '고정', code: false },
        { label: '랜덤노출', code: true },
      ],
      textType: [
        { label: '문자만', code: 1 },
        { label: '숫자만', code: 2 },
      ],
      itemSubType: [
        { label: '단일 선택', code: 1 },
        { label: '복수 선택', code: 2 },
        { label: '순위형', code: 3 },
      ],
      minValue: [
        { label: '0', code: 0 },
        { label: '1', code: 1 },
      ],
      scaleLevel: [
        { label: '3', code: 3 },
        { label: '4', code: 4 },
        { label: '5', code: 5 },
        { label: '6', code: 6 },
        { label: '7', code: 7 },
        { label: '8', code: 8 },
        { label: '9', code: 9 },
        { label: '10', code: 10 },
        { label: '11', code: 11 },
      ],
      autoType: [],
    })

    const filteredItemSubType = itemType => {
      // 격자형에서는 순위형 표시 제외
      if (itemType === 7) return codes.value.itemSubType.filter(type => type.code !== 3)

      return codes.value.itemSubType
    }

    const choiceGridDisplayCount = gridChoices => {
      const counts = gridChoices.reduce(
        (acc, x) => {
          if (x.isColumn) {
            acc.colTrue += 1
          } else {
            acc.colFalse += 1
          }
          return acc
        },
        { colTrue: 0, colFalse: 0 },
      )

      return `행: ${counts.colFalse}개 항목 / 열: ${counts.colTrue}개 항목`
    }

    const filterPipingElements = prevElements => prevElements.filter(e => e.type === 2) // 선택(복수)형만 파이핑 대상

    const fetchCodes = () => {
      axios
        .get("/fa/survey-paper/codes")
        .then(rs => {
          const { surveyAutoType } = rs.data
          codes.value.autoType = surveyAutoType
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: "코드 데이터를 불러오는데 실패하였습니다.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          })
        })
    }

    // Text Editor Popup 속성
    const editorKey = ref('')
    const editorText = ref('')
    const editorTitle = ref('')
    const editorDesc = ref('')
    const showEditor = ref(false)
    const editorConfig = {
      close: rs => {
        const { item } = props
        showEditor.value = false
        item[editorKey.value] = rs
      },
    }
    const openEditor = (key, text, title, desc) => {
      editorKey.value = key
      editorText.value = text || `empty${Math.random()}`
      editorTitle.value = title
      editorDesc.value = desc
      showEditor.value = true
    }
    // Text Editor Popup 속성 End.

    // Rich Text Editor Popup 속성
    const richKey = ref('')
    const richText = ref('')
    const richTitle = ref('')
    const richDesc = ref('')
    const showRichEditor = ref(false)
    const richConfig = {
      close: rs => {
        const { item } = props
        showRichEditor.value = false
        item[richKey.value] = rs
      },
    }
    const openRichEditor = (key, text, title, desc) => {
      richKey.value = key
      richText.value = text || `empty${Math.random()}`
      richTitle.value = title
      richDesc.value = desc
      showRichEditor.value = true
    }
    // Rich Text Editor Popup 속성 End.

    const autos = ref([])
    const autoType = ref(0)
    const showAutoConfig = ref(false)
    const autoConfig = {
      close: rs => {
        const { item } = props
        showAutoConfig.value = false
        if (rs) {
          const cloneRs = clone(rs)
          for (let i = 0; i < cloneRs.length; i += 1) {
            cloneRs[i].viewIndex = (i + 1)
          }
          item.autos = cloneRs
        }
      },
    }
    const openAutoConfig = () => {
      const { item } = props
      if (!(item.autoType === 20 || item.autoType === 21 || item.autoType === 22)) {
        toast({
          component: ToastificationContent,
          props: {
            title: "자동제출 유형 값이 환자%(약품/상병/검사)일 때만 설정 가능합니다.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        })
        return
      }
      if (item.autos) {
        autos.value = clone(item.autos)
      } else {
        autos.value = []
      }
      autoType.value = item.autoType
      showAutoConfig.value = true
    }
    const changeAutoType = () => {
      const { item } = props
      if ((item.autos && item.autos.length > 0) || item.isAutoEtc) {
        toast({
          component: ToastificationContent,
          props: {
            title: "자동제출 유형 값이 변경되어 설문항목 구성을 초기화합니다.",
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        })
        item.autos = []
        item.isAutoEtc = false
      }
    }

    const changeTextType = element => {
      if (element.type !== 3) return

      const { item } = props

      // 텍스트[한줄] 입력에서 입력범위는 '숫자만'이 아닌 경우 값을 초기화
      if (element.type === 3 && element.textType !== 2) {
        item.minCheck = null
        item.maxCheck = null
      }
    }

    const choices = ref([])
    const showChoiceConfig = ref(false)
    const choiceConfig = {
      itemType: 1, // prop이 required로 정의되어 있어, 기본값으로 단일형(1) 지정 (* 선택시 변경되기에 의미 없음)

      close: rs => {
        const { item } = props
        showChoiceConfig.value = false
        if (rs) {
          const updChoices = clone(rs)
          updChoices.forEach(ch => {
            const choice = ch

            if (choice.isFinish) {
              item.isFinishChoice = true
            }
          })
          item.choices = updChoices
        }
      },
    }
    const openChoiceConfig = itemType => {
      if (props.item.choices) {
        choices.value = clone(props.item.choices)
      } else {
        choices.value = []
      }
      choiceConfig.itemType = itemType
      showChoiceConfig.value = true
    }

    const gridChoices = ref([])
    const showChoiceGridConfig = ref(false)
    const choiceGridConfig = {
      itemType: 7,

      close: rs => {
        const { item } = props
        showChoiceGridConfig.value = false
        if (rs) {
          const updChoices = clone(rs)
          item.choices = updChoices
        }
      },
    }
    const openChoiceGridConfig = itemType => {
      if (props.item.choices) {
        gridChoices.value = clone(props.item.choices)
      } else {
        gridChoices.value = []
      }
      choiceGridConfig.itemType = itemType
      showChoiceGridConfig.value = true
    }

    const inputChoices = ref([])
    const showChoiceInputConfig = ref(false)
    const choiceInputConfig = {
      itemType: 6,

      close: rs => {
        const { item } = props
        showChoiceInputConfig.value = false
        if (rs) {
          const updChoices = clone(rs)
          item.choices = updChoices
        }
      },
    }
    const openChoiceInputConfig = itemType => {
      if (props.item.choices) {
        inputChoices.value = clone(props.item.choices)
      } else {
        inputChoices.value = []
      }
      choiceInputConfig.itemType = itemType
      showChoiceInputConfig.value = true
    }

    const showElementGroupConfig = ref(false)
    const elementGroupConfig = {
      close: rs => {
        const { item } = props
        showElementGroupConfig.value = false

        if (rs) {
          item.groupStartElementSeq = rs.groupStartElementSeq
          item.groupEndElementSeq = rs.groupEndElementSeq

          item.groupSubElementCount = item.groupElements
            .filter(el => el.seq >= rs.groupStartElementSeq && el.seq <= rs.groupEndElementSeq).length
        }
      },
    }
    const openElementGroupConfig = () => {
      showElementGroupConfig.value = true
    }

    const onSubmit = () => {
      const cloneItem = clone(props.item)

      emit('update', cloneItem)
      emit('update:show-element-edit-form', false)
    }

    const resetForm = () => {
      const { item } = props

      item.title = ''
      item.description = ''
    }

    return {
      codes,
      after,
      before,
      required,
      requiredIf,
      onSubmit,
      resetForm,

      autos,
      autoType,
      showAutoConfig,
      autoConfig,
      openAutoConfig,
      changeAutoType,

      choices,
      showChoiceConfig,
      choiceConfig,
      openChoiceConfig,

      gridChoices,
      showChoiceGridConfig,
      choiceGridConfig,
      openChoiceGridConfig,

      inputChoices,
      showChoiceInputConfig,
      choiceInputConfig,
      openChoiceInputConfig,

      showElementGroupConfig,
      elementGroupConfig,
      openElementGroupConfig,

      editorKey,
      editorText,
      editorDesc,
      editorTitle,
      showEditor,
      editorConfig,
      openEditor,

      richKey,
      richText,
      richDesc,
      richTitle,
      showRichEditor,
      richConfig,
      openRichEditor,

      changeTextType,
      filteredItemSubType,
      choiceGridDisplayCount,
      filterPipingElements,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}
</style>
