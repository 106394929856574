<!-- 격자형 문항 뷰 -->
<template>
  <table
    style="min-width: 50%"
  >
    <colgroup>
      <col :width="calcColWidth">
      <template v-for="col in getCols()">
        <col
          :key="`col-${elSeq}-${col.seq}`"
          :width="calcColWidth"
        >
      </template>
    </colgroup>
    <thead>
      <tr class="grid-line">
        <th>&nbsp;</th>
        <template v-for="col in getCols()">
          <th
            :key="`th-${elSeq}-${col.seq}`"
            style="text-align: center;"
          >
            {{ col.description }}
          </th>
        </template>
      </tr>
    </thead>
    <tbody>
      <template v-for="row in getRows()">
        <tr
          :key="`row-${elSeq}-${row.seq}`"
          class="grid-line"
        >
          <td>{{ row.description }}</td>
          <template v-for="col in getCols()">
            <td
              :key="`answer-${elSeq}-${col.seq}`"
              style="text-align: center;"
            >
              <input
                :type="(subType === 1 ? 'radio' : 'checkbox')"
                :name="`group-${elSeq}-${row.seq}`"
              >
            </td>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
import { computed } from '@vue/composition-api'

export default {
  components: {
  },

  props: {
    elSeq: {
      type: Number,
      required: true,
    },
    subType: {
      type: Number,
      required: true,
    },
    choices: {
      type: Array,
      required: true,
    },
  },

  setup(props) {
    const getCols = () => {
      const { choices } = props
      return choices.filter(ch => ch.isColumn === true)
    }

    const getRows = () => {
      const { choices } = props
      return choices.filter(ch => ch.isColumn === false)
    }

    const calcColWidth = computed(() => {
      const colCount = getCols().length + 1

      return `${Math.floor(100 / colCount)}%`
    })

    return {
      getCols,
      getRows,
      calcColWidth,
    }
  },
}
</script>

<style>
.grid-line {
  line-height: 32px
}
</style>
