<!-- [팝업] 선택 항목 구성 -->
<template>
  <b-modal
    v-model="showAutoConfig"
    cancel-variant="outline-secondary"
    centered
    ok-title="적용"
    cancel-title="취소"
    size="lg"
    title="자동제출 항목 편집"
    no-close-on-esc
    no-close-on-backdrop
    @ok.prevent="submit()"
    @close.prevent="close()"
    @cancel.prevent="close()"
  >
    <b-row
      class="m-1"
    >
      <b-col
        cols="12"
        class="d-flex justify-content-start"
      >
        <h5>환자% (약품): 약품코드 입력</h5>
      </b-col>
      <b-col
        cols="12"
        class="d-flex justify-content-start"
      >
        <h5>환자% (상병): 상병코드 입력</h5>
      </b-col>
      <b-col
        cols="12"
        class="d-flex justify-content-start"
      >
        <h5>환자% (검사): 검사코드 입력 및 결과수치 범위 지정이 필요한 경우 지정(선택사항)</h5>
      </b-col>
      <b-col
        cols="12"
        class="d-flex justify-content-start mt-50"
      >
        <h5>좌측에 표기되는 문구 값([1],[2]..[n])을 <u>"설문항목표시문구"</u> 편집 시에 사용</h5>
      </b-col>
    </b-row>
    <b-row
      class="mt-25 pr-50"
    >
      <b-col
        cols="12"
        class="mb-1 d-flex align-items-center justify-content-end"
      >
        <b-button
          variant="outline-primary"
          size="sm"
          @click.prevent="addChoice()"
        >
          <feather-icon
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span>항목 추가</span>
        </b-button>
      </b-col>
    </b-row>
    <validation-observer ref="formRules">
      <b-row class="mt-25">
        <b-col md="1">
          &nbsp;
        </b-col>
        <b-col
          :md="autoType === 22 ? 5 : 9"
          class="d-flex justify-content-center"
        >
          입력 칸에 여러개의 코드 입력 시 콤마(,)로 구분
        </b-col>
        <b-col
          v-if="autoType === 22"
          md="4"
          class="d-flex justify-content-center"
        >
          검사 결과수치 범위 (소수점5자리까지)
        </b-col>
      </b-row>
      <b-row
        v-for="(auto, idx) in autos"
        :id="`auto${idx}`"
        :key="auto.seq"
        class="mt-25"
      >
        <b-col
          cols="12"
          md="1"
          class="d-flex align-items-center justify-content-center"
        >
          <b-badge variant="light-secondary">
            {{ `[${idx + 1}]` }}
          </b-badge>
        </b-col>
        <b-col
          cols="12"
          :md="autoType === 22 ? 5 : 9"
        >
          <validation-provider
            #default="{ errors }"
            name="코드 입력"
            rules="required"
          >
            <b-form-input
              v-model="auto.codes"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
        <b-col
          v-if="autoType === 22"
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start"
        >
          <b-form-input
            v-model="auto.rangeMin"
            type="number"
          />
          <label class="ml-25 mr-50">~</label>
          <b-form-input
            v-model="auto.rangeMax"
            type="number"
          />
        </b-col>
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-start"
        >
          <b-button
            variant="outline-danger"
            size="sm"
            @click.prevent="removeChoice(auto)"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>삭제</span>
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BBadge,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    showAutoConfig: {
      type: Boolean,
      required: true,
    },
    autos: {
      type: Array,
      required: true,
      // default: () => ([]),
    },
    autoType: {
      type: Number,
      required: true,
    },
  },

  setup(props, { emit }) {
    onMounted(() => {
    })

    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const selects = ref([])
    const selectAll = ref(false)

    const autoInitState = {
      id: null,
      seq: 0,
      codes: '',
      rangeMin: null,
      rangeMax: null,
    }
    const addChoice = () => {
      const { autos } = props
      const newAuto = { ...autoInitState }

      if (autos.length !== 0) {
        newAuto.seq = Math.max(...autos.map(o => (o.seq || 0))) + 1
      } else {
        newAuto.seq = 1
      }
      autos.push(newAuto)
    }

    const removeChoice = item => {
      const idx = props.autos.indexOf(item)
      if (idx !== -1) {
        props.autos.splice(idx, 1)
      }
    }

    const close = () => {
      emit('close', null)
    }

    const submit = () => {
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        emit('close', props.autos)
      })
    }

    return {
      required,
      close,
      submit,
      selects,
      selectAll,

      addChoice,
      removeChoice,
    }
  },
}
</script>
